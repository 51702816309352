.container-detail {
    height: 100%;
    width: 500%;
    background: black;
    display: -ms-flexbox;
}

.style-title-all {
    width: 900px;
}

.style-table {
    margin-top: -2%
}

.style-pict-hor {
    width: 450px;
}

.container-list-pict::-webkit-scrollbar {
    display: none;
    overflow-x: scroll;
}

.style-pict1 {
    margin-left: 15%;
}

.style-pict2 {
    margin-left: 40%;
}

.title-container-collectionAll {
    color: white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}

.text-container {
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: lighter;
    text-align: left;
}

.modal {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.imgSize{
    width: 120%;
}

.page-container{
    padding: 50px 100px 0px 100px;
}

.my-masonry-grid-all {
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    margin-left: -40px;
    width: auto;
}

.my-masonry-grid_column-all {
    padding-left: 0px;
    background-clip: padding-box;
}

.my-masonry-grid_column-all > div {
    background: black;
    margin-bottom: 20px;
    /* margin-left: 60px; */
    margin-right: 70px;
}

.img-det-style {
    width: 109%;
    margin-top: 10%;
}

.image-wrapper a {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0ms ease-in-out;
}

.back-text {
    text-decoration: none;
    float: left;
    margin-left: 10px;
}

.img-size-arrow{
    width: 100%;
}

.vert-arrow{
    width: auto;
    margin-left: 35%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.vert-scale-arrow{
    width: auto;
    padding-top: 2%;
    margin-left: 50%;
    margin-top: 5%;
    background-color:transparent;
}


.vert-back{
    width: 50%;
    margin-left: 33%;
    margin-top: 5%;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {

    .style-title-all {
        width: 100%;
        padding-left: 15%;
        margin-top: 30%;
    }
    .img-size-arrow {
        width: 100%;
    }
    .vert-arrow {
        width: auto;
        margin-left: 35%;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .vert-scale-arrow {
        width: auto;
        background-color: black;
    }
    .vert-back {
        width: 50%;
        margin-left: 33%;
        margin-top: 5%;
    }
}

@media (min-width: 300px) {}

@media (min-width: 350px) {}

@media (min-width: 370px) {}

@media (min-width: 400px) {}

@media (max-width: 760px) {}

@media(min-width:960px) {
    .style-img-horz {
        width: 100%;
        margin-top: 4%;
        height: 100vh;
    }
    .back-text {
        margin-left: 10px
    }
}

@media(min-width:1100px) {
    .style-title-all {
        width: 900px;
        margin-left: -17%;
        margin-top: 10%;
    }
    .img-size-arrow {
        width: 400px;
    }
    .img-arrow {
        width: 30px
    }
    .style-table {
        width: auto;
        height: 100%;
        margin-top: -0.2%
    }
    .back-style {
        margin-right: -200%;
        margin-top: -14.5%;
    }
}

@media(min-width:1200px) {}

@media(min-width:1500px) {
    .style-table {
        width: auto;
        height: 100%;
        margin-top: 1%
    }
}