

.section-text-big{
    color:white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom:20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-text-big{
        font-size: 80px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}