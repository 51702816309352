
.section-text-small{
    color:white;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.32em;
    text-align: center;
    margin-bottom: 20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}