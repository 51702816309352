.container-campaign{
    height:100%;
    width:100%;
    background:black;
    padding:100px 55px;
}
.container-text-news{
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 300;
  text-align: left;
}

.container-text-time{
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    text-align: left;
    color: #FFFFFF;
}

.my-masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 80px;
    background-clip: padding-box;
}

.my-masonry-grid_column > div {
    background: black;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.imgnews{
   width: 120%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
  .my-masonry-grid {
    margin-left: -20%; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 5%; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 2%;
    margin-right: 8%;
  }

}

@media (min-width: 400px) {
  .my-masonry-grid {
    margin-left: -20%; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 5%; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 2%;
    margin-right: 8%;
  }

}

@media (min-width: 700px) {
  .my-masonry-grid {
    margin-left: -50px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 50px; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 5%;
    margin-right: 20%;
  }

}

@media (max-width: 768px) {
    .container-text-news{
        font-size: 30px;
    }

    .container-text-time{
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    /* identical to box height, or 140% */

    text-align: left;

    /* Sebastian's White */

    color: #FFFFFF;
  }

  .btn-containerNews{
    width: 280px;
    text-align: center; 
    margin-left: -2%;
  }

}

@media(min-width:960px) {
  .btn-containerNews{
    width: 750px; 
    margin: auto
  }

  .my-masonry-grid {
    margin-left: -50px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 7%;
    margin-right: 13%;
  }

}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}