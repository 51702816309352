.container-celebrities{
    height:100%;
    width:100%;
    background:black;
    padding:0px 55px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    
} 

@media(min-width:960px) {
    .btn-container{
        width: 750px; 
        margin: auto
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}