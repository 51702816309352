.container-image-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align:center;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
 .container-image-2-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align:center;
}   
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}