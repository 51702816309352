.backToArticles {
    text-align: left;
    font-weight: 300;
}

.underTitle {
    width: 100%;
    justify-content: space-between;
}

.posted {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    font-weight:100;
    margin:20px 0;
}

.article-title {
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    color: white;
}

.iconContainer {
    text-align: right;
    margin-top: -48px;
}

.iconContainer2 {
    display: none;
}

.article-container img {
    width: 100%;
    max-width: 100%;
    margin: 30px 0;
}

.article-container p {
    font-weight: 100;
    text-align: justify;
    font-size: 18px;
    line-height: 32px;
}

.container-news {
    padding-top: 2em;
    width: 50%;
    margin: auto;
    text-align: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .iconContainer {
        display: none;
    }
    .iconContainer2 {
        display: flex;
        position: flex-start;
        margin-left:-20px;
        margin-top:-20px;
        margin-bottom:20px;
    }
    .container-news {
    width: 80%;
}
}

