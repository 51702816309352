.container {
  color: white;
  padding-top: 50px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  color: white;
  /* padding-left: 10px; */
  /* width: 90px; */
}

.MuiInput-underline {
  border-bottom: 1px solid #dddddd;
}

@media(min-width:960px) {
  .container {
    padding-top: 1%;
  }
}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {
  .container {
    padding-top: 1%;
  }
}