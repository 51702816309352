*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Raleway', sans-serif;
}
body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

html {
  scroll-behavior: smooth;
}
.hidden{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.1s linear;
}
.navBar{
  visibility: visible;
  opacity: 1;
  transition: visibility 1s, opacity 0.1s linear;
}
.container-image-news{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2-news{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}

.container-text{
    margin-top:100px
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .container-image-news{
        background-position: -145px 0;
    }
    
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}


.section-text-medium{
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    /* identical to box height, or 140% */

    text-align: center;

    /* Sebastian's White */

    color: #FFFFFF;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-text-medium{
        font-size: 30px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-our-collections {
    height: 100%;
    width: 100%;
    background: black;
    padding: 20px 55px;
}

.imgnews {
    width: 120%;
}

.col-subtitle {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 40px;
    /* identical to box height, or 286% */
    text-align: center;
    letter-spacing: 0.32em;
    color: #FFFFFF;
}

.col-title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    color: white;
    text-align: center;
}

.col-body {
    font-style: normal;
    font-weight: lighter;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
}

.select-selected {
    background-color: transparent;
    margin-left: 45%;
    padding-top: 1%;
    width: 58%;
}

.select-selected2 {
    background-color: transparent;
    margin-top: 12.5%;
    margin-left: 11%;
}

.style-arrow {
    margin-left: 95%;
    margin-top: -13%;
    width: 8%;
}

.style-sort {
    width: auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
    .btn-container-col {
        width: 105%;
        margin-left: 8%;
    }
}

@media (min-width: 400px) {}

@media (min-width: 700px) {}

@media (max-width: 768px) {}

@media(min-width:960px) {}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {}
.btn-transparent{
    height: 56px;
    padding:0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background:transparent;
    color:white;
    font-weight:300;
    letter-spacing: 0.16em;
    width:100%;
    cursor: pointer;
}
.btn-filled{
    height: 56px;
    padding:0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background:white;
    color:black;
    font-weight:300;
    letter-spacing: 0.16em;
    width:100%;
    cursor: pointer;
}



/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */



@media (min-width: 576px) {
}

@media(min-width:960px) {
    .btn-filled{
        width:100%;
    }
    
    .btn-transparent{
        width:100%;
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}

.section-text-small{
    color:white;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.32em;
    text-align: center;
    margin-bottom: 20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-footer{
    height:100%;
    width:100%;
    background:black;
    padding:20px 90px;
}



.container-p{

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    -webkit-justify-content:center;
            justify-content:center;

    color: #FFFFFF;

}

.container-pfoot1{

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding-top : 2.5%;
    padding-bottom: 2.5%;

    color: #FFFFFF;

}

.container-pfoot2{

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding-top : 2.5%;
    padding-bottom: 2.5%;

    color: #FFFFFF;

}

.anthem-link, .anthem-link:focus, .anthem-link:hover{
    color:white;
    text-decoration: underline;
}

.container-link{

    -webkit-text-decoration-line: underline;

            text-decoration-line: underline;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    -webkit-justify-content:center;
            justify-content:center;

    color: #FFFFFF;

}

.fav-logo-container{
    max-width: 80%;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
    
}

@media (max-width: 768px) {

    .container-pfoot1{

        font-size: 9px;
        text-align: center;
    
    }

    .container-pfoot2{

        font-size: 9px;
        text-align: center;
    
    }

    .logo-contact{
        padding: 20px;
        width: 10%;
    }
    .logo-container{
        margin-left: 0%;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    }

    .container-footer-bottom{
        width: 190%;
        margin-left: -45%;
    }
    .container-hr{
        width: 160%;
        margin-left: -30%;
    }
}

@media(min-width:960px) {
    /* .logo-contact{
        padding: 20px;
        width: 10%;
    } */
    /* .logo-container{
        width: 50%;
        margin-left: 40%;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
    } */

    .container-pfoot1{

        text-align: left;
    
    }

    .container-pfoot2{

        text-align: right;
    
    }
    
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}


.section-paragraph{
    color:white;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-paragraph{
        font-size: 16px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-detail {
    height: 100%;
    width: 500%;
    background: black;
    display: -ms-flexbox;
}

.style-title {
    width: 900px;
}

.style-table {
    margin-top: -2%
}

.style-pict-hor {
    width: 450px;
}

.container-list-pict::-webkit-scrollbar {
    display: none;
    overflow-x: scroll;
}

.style-pict1 {
    margin-left: 15%;
}

.style-pict2 {
    margin-left: 40%;
}

.title-container {
    color: white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}

.text-container {
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: lighter;
    text-align: left;
}

.modal {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.my-masonry-grid-det {
    /* Not needed if autoprefixing */
    /* Not needed if autoprefixing */
    display: -webkit-flex;
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column-det {
    padding-left: 80px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column-det>div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.img-det-style {
    width: 109%;
    margin-top: 10%;
}

.image-wrapper a {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 1;
    transition: opacity 0ms ease-in-out;
}

.back-text {
    text-decoration: none;
    float: left;
    margin-left: 10px;
}

.vert-arrow{
    width: auto;
    margin-left: 35%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.vert-scale-arrow{
    width: auto;
    padding-top: 2%;
    margin-left: 50%;
    margin-top: 5%;
    background-color:transparent;
}

.img-arrow {
    margin-top: -40%;
}
.vert-back{
    width: 50%;
    margin-left: 33%;
    margin-top: 5%;
}

.style-back{
    width: 300px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
    .my-masonry-grid-det {
        margin-left: -16%;
        /* gutter size offset */
    }
    .my-masonry-grid_column-det {
        padding-left: 5%;
        /* gutter size */
    }
    /* Style your items */
    .my-masonry-grid_column-det>div {
        /* change div to reference your elements you put in <Masonry> */
        margin-left: 2%;
        margin-right: 8%;
    }
    .style-title {
        width: 100%;
        padding-left: 15%;
        margin-top: 30%;
    }
    .vert-arrow {
        width: auto;
        margin-left: 35%;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .vert-scale-arrow {
        width: auto;
        background-color: black;
    }
    .vert-back {
        width: 50%;
        margin-left: 33%;
        margin-top: 5%;
    }
}

@media (min-width: 300px) {}

@media (min-width: 350px) {}

@media (min-width: 370px) {}

@media (min-width: 400px) {}

@media (max-width: 760px) {}

@media(min-width:960px) {
    .style-img-horz {
        width: 100%;
        margin-top: 4%;
        height: 100vh;
    }
    .back-text {
        margin-left: 10px
    }

}

@media(min-width:1100px) {
    .style-title {
        width: 900px;
        margin-left: -5%;
        margin-top: 10%;
    }
    
    .style-table {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 100%;
        margin-top: -0.2%
    }
}

@media(min-width:1200px) {}

@media(min-width:1500px) {
    .style-table {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 100%;
        margin-top: 1%
    }
}

.section-text-small-concierge{
    color:white;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.2em;
    text-align: center;
    
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.section-paragraph-concierge{
    color:white;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-align: center;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-paragraph-concierge{
        font-size: 17px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-image{
    height:100vh;
    width:100%;
    /* background-image: url('../../images/signature/main_image.png'); */
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2{
    height:100vh;
    width:100%;
    /* background-image: url('../../images/signature/main_image.png'); */
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .container-image{
        background-position: -145px 0;
    }
    
    .container-image-2{
        background-position: -145px 0;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}


.section-text-big{
    color:white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom:20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-text-big{
        font-size: 80px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-campaign{
    height:100%;
    width:100%;
    background:black;
    padding:100px 55px;
    
}
/* pengaturean tinggu dan lebar */
.image1{
    width: 891px;
    height: 503px; 
}

.image2{
    width: 692.33px;
    height: 390px;
}
.image3{
    width: 492px;
    height: 738px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (min-width: 300px) {
    .image1{
        width: 150%;
        height: auto;
        margin-top: 0%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 150%;
        height: auto;
        margin-top: 5%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: 150%;
        height: auto;
        margin-top: 10%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: auto;
    }

   
}

@media (min-width: 350px) {
    .image1{
        width: 145%;
        height: auto;
        margin-top: 0%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 145%;
        height: auto;
        margin-top: 5%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: 145%;
        height: auto;
        margin-top: 10%;
        margin-left: -25%;
        margin-right: auto;
        margin-bottom: auto;
    }

   
}

@media (min-width: 370px) {
    .image1{
        width: 135%;
        height: auto;
        margin-top: 0%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 140%;
        height: auto;
        margin-top: 5%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: 150%;
        height: auto;
        margin-top: 10%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: auto;
    }

   
}

@media (min-width: 400px) {
    .image1{
        width: 135%;
        height: auto;
        margin-top: 0%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 138%;
        height: auto;
        margin-top: 30%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: auto;
        height: 77%;
        margin-top: 10%;
        margin-left: -20%;
        margin-right: auto;
        margin-bottom: auto;
    }

   
}

@media (min-width: 760px) {

    /* untuk pengaturean responsive dari img */
    .image1{
        width: 65%;
        height: auto;
        margin-top: 0%;
        margin-left: -10%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 50%;
        height: auto;
        margin-top: -20%;
        margin-left: 0%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: 30%;
        height: auto;
        margin-top: 13%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
    }


} 

@media(min-width:960px) {
    .btn-container{
        width: 750px; 
        margin: auto
    }
    /* untuk pengaturean responsive dari img */
    .image1{
        width: 65%;
        height: auto;
        margin-top: 0%;
        margin-left: -5%;
        margin-right: auto;
        margin-bottom: auto;
    }
    
    .image2{
        width: 50%;
        height: auto;
        margin-top: -20%;
        margin-left: 3%;
        margin-right: auto;
        margin-bottom: 5%;

    }
    .image3{
        width: 30%;
        height: auto;
        margin-top: 13%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: auto;
    }

}

@media(min-width:1100px) {
    
}

@media(min-width:1200px) {
    
}
@media(min-width:1500px) {
   
    
}
.container-celebrities{
    height:100%;
    width:100%;
    background:black;
    padding:0px 55px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    
} 

@media(min-width:960px) {
    .btn-container{
        width: 750px; 
        margin: auto
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
body {
  background-color: black;
}

.slick-slide {
  outline: none;
}
.slick-slide div {
  outline: none;
}

.App {
  width: 50%;
  margin: 10rem auto;
  height: 570px;
}

.slick-dots li button:before {
  color: lightgray;
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: rgb(243, 237, 237);
  border: 1px solid rgb(243, 237, 237);
  border-radius: 50%;
  font-size: 10px;
}

.slide img {
  width: 20rem;
  padding: 25px;
  margin: 0 -28px;
}

p {
  text-align: center;
  color: white;
}
.slide {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0.5;
}

.slide3 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0;
}

.activeSlide {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.slick-prev {
  left: -52px !important;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

@media only screen and (max-width: 1024px) {
  .slide img {
    width: 13.5rem;
    padding: 25px;
    margin: 0 -28px;
  }

  .slide3 p {
    width: 100%;
  }

  .slide3 {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media only screen and (max-width: 785px) {
  .slick-dots li.slick-active button:before {
    border: 10px solid rgb(243, 237, 237);
  }
  .slide {
    margin-left: -25%;
    -webkit-transform: scale(0.001);
            transform: scale(0.001);
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .judulImage {
    width: 150%;
    margin-left: 2%;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1024px) {
}

.container-campaign{
    height:100%;
    width:100%;
    background:black;
    padding:100px 55px;
}
.container-text-news{
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 300;
  text-align: left;
}

.container-text-time{
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    text-align: left;
    color: #FFFFFF;
}

.my-masonry-grid {
    display: -webkit-flex;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 80px;
    background-clip: padding-box;
}

.my-masonry-grid_column > div {
    background: black;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.imgnews{
   width: 120%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
  .my-masonry-grid {
    margin-left: -20%; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 5%; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 2%;
    margin-right: 8%;
  }

}

@media (min-width: 400px) {
  .my-masonry-grid {
    margin-left: -20%; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 5%; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 2%;
    margin-right: 8%;
  }

}

@media (min-width: 700px) {
  .my-masonry-grid {
    margin-left: -50px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 50px; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 5%;
    margin-right: 20%;
  }

}

@media (max-width: 768px) {
    .container-text-news{
        font-size: 30px;
    }

    .container-text-time{
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    /* identical to box height, or 140% */

    text-align: left;

    /* Sebastian's White */

    color: #FFFFFF;
  }

  .btn-containerNews{
    width: 280px;
    text-align: center; 
    margin-left: -2%;
  }

}

@media(min-width:960px) {
  .btn-containerNews{
    width: 750px; 
    margin: auto
  }

  .my-masonry-grid {
    margin-left: -50px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-left: 7%;
    margin-right: 13%;
  }

}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-campaign{
    height:100%;
    width:100%;
    background:black;
    padding:100px 55px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .img{
        width: 100%; 
        padding: 13%;
    }

}

@media(min-width:960px) {
    .img{
        width: 100%; 
        padding: 13%;
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-image-news{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2-news{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .container-image-news{
        background-position: -145px 0;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}

.container-news-landing{
    height:100%;
    width:100%;
    background:black;
    padding:20px 55px;
}
.imgnews{
   width: 120%;
}

.container-text-col{
    font-style: normal;
    font-weight: 300;
    font-size: 35px;
    /* identical to box height, or 140% */

    text-align: center;

    /* Sebastian's White */

    color: #FFFFFF;
}

.container-text-time-col{
    font-style: normal;
    font-weight: 150;
    font-size: 20px;
    /* identical to box height, or 140% */

    text-align: center;

    /* Sebastian's White */

    color: #FFFFFF;
}

.select-selected {
    background-color: transparent;
    margin-left: 45%;
    padding-top: 1%;
    width: 58%;
}

.select-selected2 {
    background-color: transparent;
    margin-top: 12.5%;
    margin-left: 11%;
}
.style-arrow-news{
    margin-left: 280%;
    margin-top: -78.5%;
}
.style-sort{
    width: auto;
}

.container-text-news{
    text-align:left;
}
.tanggal{
    font-weight: 300;
    text-align:left;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
  

}

@media (min-width: 400px) {
 

}

@media (min-width: 700px) {
 

}

@media (max-width: 768px) {
}

@media(min-width:960px) {
  .btn-container-col{
      width:105%;
  }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-image-aboutUs{
    height:120vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2-aboutUs{
    height:120vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}

.container-text-aboutUs{
    color:white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: center;
    width: 40%;
}

.cover-about-smal{
    padding-bottom: 30%;
}

.cover-about-big{
    padding-top: 50%;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .container-image-aboutUs{
        width: 100%;
    }

    .container-image-2-aboutUs{
        width: 100%;
        background-position: -350px 0;
    }

    .cover-about-big p{
        font-size: 350%;
    }

    .cover-about-smal{
        padding-bottom: 50%;
    }
    .cover-about-big{
        padding-top: 50%;
    }

    .cover-about-img{
        position: relative;
    }

    .cover-about-img-btn{
        position: absolute;
        margin-left: 17%;
        top: -58px;
    }

}
@media(min-width:960px) {
}

@media(min-width:1100px) {

}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container-aboutUs {
    height: 100%;
    width: 100%;
    background: black;
}

.container-title {
    width: 65%;
    background: black;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.container-main {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.ourstory-text-title {
    font-size: 45px;
    color: white;
    text-align: center;
}

.ourstory-text-title-small {
    font-size: 25px;
    color: white;
    text-align: center;
    font-weight: 100
}

.container-title-main1 {
    margin-bottom: 5%;
}

.container-title-main2 {
    margin-bottom: 5%;
}

.container-main-bot {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.slide-contain {
    margin-top: 10%;
}

.slide-contain1 {
    margin-top: 10%;
    margin-bottom: 10%;
}

.container-quotes {
    padding: 10px 0;
}

.section-text-medium-qotes {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    width: 100%;
    margin: auto;
    /* identical to box height, or 140% */
    text-align: center;
    /* Sebastian's White */
    color: #FFFFFF;
}

.section-text-medium-img-det {
    color: white;
    font-size: 18px;
    line-height: 32px;
    font-style: normal;
    font-weight: 100;
    text-align: center;
}

.section-text-medium-art {
    background: -webkit-linear-gradient(#eee, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: justify;
    font-size: 18px;
    line-height: 32px;
    font-style: normal;
    font-weight: 100;
}

.section-text-medium-art button {
    background: -webkit-linear-gradient(#eee, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
}

.section-text-medium-art p {
    word-break: break-word;
}

.article-container-fist-img {
    width: 100%;
    margin: 30px 0;
}

.article-container-aboutUs img {
    width: 100%;
    max-width: 100%;
    margin: 30px 0;
}

.article-container-aboutUs p {
    font-weight: 100;
    text-align: justify;
    background: -webkit-linear-gradient(white, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    font-size: 18px;
    line-height: 32px;
}

.image-art-container {
    width: 100%;
    margin-left: -6.5%;
}

.container-text-aboutUs-art {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}


.btn-transparent-art {
    height: 56px;
    padding: 0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: white;
    font-weight: 300;
    letter-spacing: 0.16em;
    width: 100%
}

.container-award {
    width: 200%;
    margin-bottom: 15%;
    margin-top: 20%;
}

.container-award p {
    font-size: 120px;
    margin-left: -50%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {}

@media (max-width: 768px) {
    .container-title {
        width: 100%;
    }
}

@media(min-width:960px) {
    .container-quotes {
        padding: 40px
    }
    .ourstory-text-title {
        font-size: 96px;
        line-height: 96px;
        color: white;
        text-align: center;
    }
    .ourstory-text-title-small {
        font-size: 45px;
        color: white;
        text-align: center;
    }
    .btn-transparent-art {
        height: 56px;
        padding: 0 40px;
        border-radius: 0px;
        border: 1px solid #FFFFFF;
        background: transparent;
        color: white;
        font-weight: 300;
        letter-spacing: 0.16em;
        width: 300px;
        margin: -50px -79px; 
        position:relative;
        top:50%; 
        left:40%;
    }
}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {}
body {
  background-color: black;
}

.app-aboutUs1 {
  width: 155%;
  margin-left: -27.5%;
  margin-bottom: 15%;
  height: 570px;
}

.slide-aboutUs1 {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 1;
  -webkit-align-items: center;
          align-items: center;
}

.slide-aboutUs1 img {
  width: 95%;
  margin-left: 3%;
  margin-bottom: 15%;
}

.slide-title {
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 15%;
}

.slide-title p {
  font-size: 200%;
}

p {
  text-align: center;
  color: white;
}

.activeSlide {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
.slick-prev {
  left: -52px !important;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  margin-top: -500%;
  color: #000000;
  font-size: 48px;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 785px) {
  .slide-aboutUs1 img {
    width: 95%;
    margin-left: 2.5%;
  }

  .slide-aboutUs1 p {
    width: 110%;
    margin-left: -5%;
    margin-top: 5%;
  }

  .slide-aboutUs1 {
    margin-left: 98%;
  }

  .app-aboutUs1 {
    width: 100%;
    margin-left: 0%;
  }
  .disabled {
    cursor: not-allowed;
  }
  .slick-next:not(.disabled):before,
  .slick-prev:not(.disabled):before {
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) {
}

body {
  background-color: black;
}

.App {
  width: 50%;
  margin: 10rem auto;
  height: 570px;
}

.slick-dots li button:before {
  color: lightgray;
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: rgb(243, 237, 237);
  border: 1px solid rgb(243, 237, 237);
  border-radius: 50%;
  font-size: 10px;
}

.slide img {
  width: 20rem;
  padding: 25px;
  margin: 0 -28px;
}

p {
  text-align: center;
  color: white;
}

.slide3 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0;
  margin-bottom: 10%;
  margin-top: 5%;
}

.slide3 p{
  line-height: 55px;
  margin-top: 2%;
}

.activeSlide {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 785px) {

  .slide3 p{
    width: 150%;
    font-size: 40px;
    line-height: 150%;
    margin-left: -25%;
    
  }

  .slide3{
    -webkit-transform: scale(1);
            transform: scale(1);
    width: 100%;
    margin-top: 10%;
    margin-bottom: 15%;
    word-break: break-word;
  }

}

@media only screen and (min-width: 1024px) {


}



.section-text-medium-news{
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    width: 70%;
    /* identical to box height, or 140% */
    text-align: justify;

    /* Sebastian's White */

    color: #FFFFFF;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-text-medium-news{
        font-size: 30px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}


.section-paragraph-news{
    color:white;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justify;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-paragraph-news{
        font-size: 16px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.backToArticles {
    text-align: left;
    font-weight: 300;
}

.underTitle {
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.posted {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    font-weight:100;
    margin:20px 0;
}

.article-title {
    text-align: left;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    color: white;
}

.iconContainer {
    text-align: right;
    margin-top: -48px;
}

.iconContainer2 {
    display: none;
}

.article-container img {
    width: 100%;
    max-width: 100%;
    margin: 30px 0;
}

.article-container p {
    font-weight: 100;
    text-align: justify;
    font-size: 18px;
    line-height: 32px;
}

.container-news {
    padding-top: 2em;
    width: 50%;
    margin: auto;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (max-width: 768px) {
    .iconContainer {
        display: none;
    }
    .iconContainer2 {
        display: -webkit-flex;
        display: flex;
        position: flex-start;
        margin-left:-20px;
        margin-top:-20px;
        margin-bottom:20px;
    }
    .container-news {
    width: 80%;
}
}


.container-image-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
    position: absolute;
}
.container-image-2-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
 .container-image-2-news-detail{
    height:50vh;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    text-align:center;
}   
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}
.container {
  color: white;
  padding-top: 50px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  color: white;
  /* padding-left: 10px; */
  /* width: 90px; */
}

.MuiInput-underline {
  border-bottom: 1px solid #dddddd;
}

@media(min-width:960px) {
  .container {
    padding-top: 1%;
  }
}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {
  .container {
    padding-top: 1%;
  }
}
.container-detail {
    height: 100%;
    width: 500%;
    background: black;
    display: -ms-flexbox;
}

.style-title-all {
    width: 900px;
}

.style-table {
    margin-top: -2%
}

.style-pict-hor {
    width: 450px;
}

.container-list-pict::-webkit-scrollbar {
    display: none;
    overflow-x: scroll;
}

.style-pict1 {
    margin-left: 15%;
}

.style-pict2 {
    margin-left: 40%;
}

.title-container-collectionAll {
    color: white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}

.text-container {
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: lighter;
    text-align: left;
}

.modal {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.imgSize{
    width: 120%;
}

.page-container{
    padding: 50px 100px 0px 100px;
}

.my-masonry-grid-all {
    display: -webkit-flex;
    display: flex;
    margin-left: -40px;
    width: auto;
}

.my-masonry-grid_column-all {
    padding-left: 0px;
    background-clip: padding-box;
}

.my-masonry-grid_column-all > div {
    background: black;
    margin-bottom: 20px;
    /* margin-left: 60px; */
    margin-right: 70px;
}

.img-det-style {
    width: 109%;
    margin-top: 10%;
}

.image-wrapper a {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 1;
    transition: opacity 0ms ease-in-out;
}

.back-text {
    text-decoration: none;
    float: left;
    margin-left: 10px;
}

.img-size-arrow{
    width: 100%;
}

.vert-arrow{
    width: auto;
    margin-left: 35%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.vert-scale-arrow{
    width: auto;
    padding-top: 2%;
    margin-left: 50%;
    margin-top: 5%;
    background-color:transparent;
}


.vert-back{
    width: 50%;
    margin-left: 33%;
    margin-top: 5%;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {

    .style-title-all {
        width: 100%;
        padding-left: 15%;
        margin-top: 30%;
    }
    .img-size-arrow {
        width: 100%;
    }
    .vert-arrow {
        width: auto;
        margin-left: 35%;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .vert-scale-arrow {
        width: auto;
        background-color: black;
    }
    .vert-back {
        width: 50%;
        margin-left: 33%;
        margin-top: 5%;
    }
}

@media (min-width: 300px) {}

@media (min-width: 350px) {}

@media (min-width: 370px) {}

@media (min-width: 400px) {}

@media (max-width: 760px) {}

@media(min-width:960px) {
    .style-img-horz {
        width: 100%;
        margin-top: 4%;
        height: 100vh;
    }
    .back-text {
        margin-left: 10px
    }
}

@media(min-width:1100px) {
    .style-title-all {
        width: 900px;
        margin-left: -17%;
        margin-top: 10%;
    }
    .img-size-arrow {
        width: 400px;
    }
    .img-arrow {
        width: 30px
    }
    .style-table {
        width: auto;
        height: 100%;
        margin-top: -0.2%
    }
    .back-style {
        margin-right: -200%;
        margin-top: -14.5%;
    }
}

@media(min-width:1200px) {}

@media(min-width:1500px) {
    .style-table {
        width: auto;
        height: 100%;
        margin-top: 1%
    }
}
