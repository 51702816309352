.container-campaign{
    height:100%;
    width:100%;
    background:black;
    padding:100px 55px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .img{
        width: 100%; 
        padding: 13%;
    }

}

@media(min-width:960px) {
    .img{
        width: 100%; 
        padding: 13%;
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}