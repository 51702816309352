.section-paragraph-concierge{
    color:white;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-align: center;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-paragraph-concierge{
        font-size: 17px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}