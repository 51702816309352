.btn-transparent{
    height: 56px;
    padding:0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background:transparent;
    color:white;
    font-weight:300;
    letter-spacing: 0.16em;
    width:100%;
    cursor: pointer;
}
.btn-filled{
    height: 56px;
    padding:0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background:white;
    color:black;
    font-weight:300;
    letter-spacing: 0.16em;
    width:100%;
    cursor: pointer;
}



/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */



@media (min-width: 576px) {
}

@media(min-width:960px) {
    .btn-filled{
        width:100%;
    }
    
    .btn-transparent{
        width:100%;
    }
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}