

.section-text-medium-news{
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    width: 70%;
    /* identical to box height, or 140% */
    text-align: justify;

    /* Sebastian's White */

    color: #FFFFFF;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {
}

@media (max-width: 768px) {
    .section-text-medium-news{
        font-size: 30px;
    }
}

@media(min-width:960px) {
}

@media(min-width:1100px) {
}

@media(min-width:1200px) {
}
@media(min-width:1500px) {
    
}