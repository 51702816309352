.container-aboutUs {
    height: 100%;
    width: 100%;
    background: black;
}

.container-title {
    width: 65%;
    background: black;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.container-main {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.ourstory-text-title {
    font-size: 45px;
    color: white;
    text-align: center;
}

.ourstory-text-title-small {
    font-size: 25px;
    color: white;
    text-align: center;
    font-weight: 100
}

.container-title-main1 {
    margin-bottom: 5%;
}

.container-title-main2 {
    margin-bottom: 5%;
}

.container-main-bot {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.slide-contain {
    margin-top: 10%;
}

.slide-contain1 {
    margin-top: 10%;
    margin-bottom: 10%;
}

.container-quotes {
    padding: 10px 0;
}

.section-text-medium-qotes {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    width: 100%;
    margin: auto;
    /* identical to box height, or 140% */
    text-align: center;
    /* Sebastian's White */
    color: #FFFFFF;
}

.section-text-medium-img-det {
    color: white;
    font-size: 18px;
    line-height: 32px;
    font-style: normal;
    font-weight: 100;
    text-align: center;
}

.section-text-medium-art {
    background: -webkit-linear-gradient(#eee, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: justify;
    font-size: 18px;
    line-height: 32px;
    font-style: normal;
    font-weight: 100;
}

.section-text-medium-art button {
    background: -webkit-linear-gradient(#eee, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
}

.section-text-medium-art p {
    word-break: break-word;
}

.article-container-fist-img {
    width: 100%;
    margin: 30px 0;
}

.article-container-aboutUs img {
    width: 100%;
    max-width: 100%;
    margin: 30px 0;
}

.article-container-aboutUs p {
    font-weight: 100;
    text-align: justify;
    background: -webkit-linear-gradient(white, white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    font-size: 18px;
    line-height: 32px;
}

.image-art-container {
    width: 100%;
    margin-left: -6.5%;
}

.container-text-aboutUs-art {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}


.btn-transparent-art {
    height: 56px;
    padding: 0 40px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: white;
    font-weight: 300;
    letter-spacing: 0.16em;
    width: 100%
}

.container-award {
    width: 200%;
    margin-bottom: 15%;
    margin-top: 20%;
}

.container-award p {
    font-size: 120px;
    margin-left: -50%;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 576px) {}

@media (max-width: 768px) {
    .container-title {
        width: 100%;
    }
}

@media(min-width:960px) {
    .container-quotes {
        padding: 40px
    }
    .ourstory-text-title {
        font-size: 96px;
        line-height: 96px;
        color: white;
        text-align: center;
    }
    .ourstory-text-title-small {
        font-size: 45px;
        color: white;
        text-align: center;
    }
    .btn-transparent-art {
        height: 56px;
        padding: 0 40px;
        border-radius: 0px;
        border: 1px solid #FFFFFF;
        background: transparent;
        color: white;
        font-weight: 300;
        letter-spacing: 0.16em;
        width: 300px;
        margin: -50px -79px; 
        position:relative;
        top:50%; 
        left:40%;
    }
}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {}