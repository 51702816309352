.container-our-collections {
    height: 100%;
    width: 100%;
    background: black;
    padding: 20px 55px;
}

.imgnews {
    width: 120%;
}

.col-subtitle {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 40px;
    /* identical to box height, or 286% */
    text-align: center;
    letter-spacing: 0.32em;
    color: #FFFFFF;
}

.col-title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 56px;
    color: white;
    text-align: center;
}

.col-body {
    font-style: normal;
    font-weight: lighter;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
}

.select-selected {
    background-color: transparent;
    margin-left: 45%;
    padding-top: 1%;
    width: 58%;
}

.select-selected2 {
    background-color: transparent;
    margin-top: 12.5%;
    margin-left: 11%;
}

.style-arrow {
    margin-left: 95%;
    margin-top: -13%;
    width: 8%;
}

.style-sort {
    width: auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
    .btn-container-col {
        width: 105%;
        margin-left: 8%;
    }
}

@media (min-width: 400px) {}

@media (min-width: 700px) {}

@media (max-width: 768px) {}

@media(min-width:960px) {}

@media(min-width:1100px) {}

@media(min-width:1200px) {}

@media(min-width:1500px) {}