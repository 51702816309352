@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  background-color: black;
}

.slick-slide {
  outline: none;
}
.slick-slide div {
  outline: none;
}

.App {
  width: 50%;
  margin: 10rem auto;
  height: 570px;
}

.slick-dots li button:before {
  color: lightgray;
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: rgb(243, 237, 237);
  border: 1px solid rgb(243, 237, 237);
  border-radius: 50%;
  font-size: 10px;
}

.slide img {
  width: 20rem;
  padding: 25px;
  margin: 0 -28px;
}

p {
  text-align: center;
  color: white;
}
.slide {
  transform: scale(0.8);
  transition: transform 300ms;
  opacity: 0.5;
}

.slide3 {
  transform: scale(0.8);
  transition: transform 300ms;
  opacity: 0;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.slick-prev {
  left: -52px !important;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

@media only screen and (max-width: 1024px) {
  .slide img {
    width: 13.5rem;
    padding: 25px;
    margin: 0 -28px;
  }

  .slide3 p {
    width: 100%;
  }

  .slide3 {
    transform: scale(1);
  }
}

@media only screen and (max-width: 785px) {
  .slick-dots li.slick-active button:before {
    border: 10px solid rgb(243, 237, 237);
  }
  .slide {
    margin-left: -25%;
    transform: scale(0.001);
    transition: transform 300ms;
  }
  .judulImage {
    width: 150%;
    margin-left: 2%;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1024px) {
}
