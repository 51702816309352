.container-detail {
    height: 100%;
    width: 500%;
    background: black;
    display: -ms-flexbox;
}

.style-title {
    width: 900px;
}

.style-table {
    margin-top: -2%
}

.style-pict-hor {
    width: 450px;
}

.container-list-pict::-webkit-scrollbar {
    display: none;
    overflow-x: scroll;
}

.style-pict1 {
    margin-left: 15%;
}

.style-pict2 {
    margin-left: 40%;
}

.title-container {
    color: white;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}

.text-container {
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: lighter;
    text-align: left;
}

.modal {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.my-masonry-grid-det {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column-det {
    padding-left: 80px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column-det>div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.img-det-style {
    width: 109%;
    margin-top: 10%;
}

.image-wrapper a {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0ms ease-in-out;
}

.back-text {
    text-decoration: none;
    float: left;
    margin-left: 10px;
}

.vert-arrow{
    width: auto;
    margin-left: 35%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.vert-scale-arrow{
    width: auto;
    padding-top: 2%;
    margin-left: 50%;
    margin-top: 5%;
    background-color:transparent;
}

.img-arrow {
    margin-top: -40%;
}
.vert-back{
    width: 50%;
    margin-left: 33%;
    margin-top: 5%;
}

.style-back{
    width: 300px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (min-width: 300px) {
    .my-masonry-grid-det {
        margin-left: -16%;
        /* gutter size offset */
    }
    .my-masonry-grid_column-det {
        padding-left: 5%;
        /* gutter size */
    }
    /* Style your items */
    .my-masonry-grid_column-det>div {
        /* change div to reference your elements you put in <Masonry> */
        margin-left: 2%;
        margin-right: 8%;
    }
    .style-title {
        width: 100%;
        padding-left: 15%;
        margin-top: 30%;
    }
    .vert-arrow {
        width: auto;
        margin-left: 35%;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    .vert-scale-arrow {
        width: auto;
        background-color: black;
    }
    .vert-back {
        width: 50%;
        margin-left: 33%;
        margin-top: 5%;
    }
}

@media (min-width: 300px) {}

@media (min-width: 350px) {}

@media (min-width: 370px) {}

@media (min-width: 400px) {}

@media (max-width: 760px) {}

@media(min-width:960px) {
    .style-img-horz {
        width: 100%;
        margin-top: 4%;
        height: 100vh;
    }
    .back-text {
        margin-left: 10px
    }

}

@media(min-width:1100px) {
    .style-title {
        width: 900px;
        margin-left: -5%;
        margin-top: 10%;
    }
    
    .style-table {
        width: fit-content;
        height: 100%;
        margin-top: -0.2%
    }
}

@media(min-width:1200px) {}

@media(min-width:1500px) {
    .style-table {
        width: fit-content;
        height: 100%;
        margin-top: 1%
    }
}